/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"
import { Container, Fade, makeStyles } from "@material-ui/core"
import { ThemeProvider } from "@material-ui/styles"
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import createOverrides from "../utils/theme"

const useStyles = makeStyles(theme => ({
  container: {
    position: `relative`,
    minHeight: `100vh`,
    padding: `0 1.45rem 1.45rem`
  },
  contentWrap: {
    paddingBottom: `2.5rem`
  },
  footer: {
    position: `absolute`,
    bottom: 0,
    
  }
}))

const baseTheme = responsiveFontSizes(createMuiTheme({
  typography: {
    fontFamily: [
      `Work Sans, Spectral`
    ],
    fontSize: 14,
    fontDisplay: `swap`,
    h4: {
      fontFamily: `Work Sans`,
      fontWeight: 700
    },
    h5: {
      fontFamily: `Work Sans`,
      fontWeight: 600
    },
    body1: {
      fontFamily: `Work Sans`
    }
  }
}))
//baseTheme = responsiveFontSizes(baseTheme)

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <Fade in={true}>
      <ThemeProvider theme={{
        ...baseTheme,
        overrides: createOverrides(baseTheme)
      }}>
        <Container maxWidth='md' className={useStyles().container}>
          <Header {...data.site.siteMetadata.title} />
          <main className={useStyles().contentWrap}>{children}</main>
          <Footer className={useStyles().footer} />
        </Container>
      </ThemeProvider>
    </Fade>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import PropTypes from "prop-types"
import React from "react"

import { Divider, Grid } from '@material-ui/core'
import { BoxedLogo } from "../utils/socials"

const Footer = () => (
  <footer
    style={{
      position: `absolute`,
      bottom: 0,
      height: `2.5rem`,
      width: `95%`
    }}
  >
    <Divider style={{ marginBottom: `0.75rem` }}/>
    <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
      <BoxedLogo />
      © Zain Khalid 2020
    </Grid>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: `Zain Khalid`,
}

export default Footer

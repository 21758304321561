import React from "react"
import SvgIcon from "@material-ui/core/SvgIcon"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  socialIcons: {
    fontSize: `1.70rem`
  },
  logoIcon: {
    fontSize: `4.00rem`
  },
  boxLogo: {
    fontSize: `1.70rem`,
    fill: `linear-gradient(#e66465, #9198e5)`,
    stroke: `linear-gradient(#e66465, #9198e5)`,
    color: `linear-gradient(#e66465, #9198e5)`
  }
}))

export function LinkedInIcon(props) {
  return(
    <SvgIcon {...props} classes={{ root: useStyles().socialIcons }}>
      <defs>
        <linearGradient x1="0%" y1="0%" x2="101.999998%" y2="100.999999%" id="grad">
            <stop stop-color="#1100B3" offset="0%"></stop>
            <stop stop-color="#B700D5" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g fill="none" stroke="url(#grad)" stroke-width="0.85">
        <path d="M0,0 L0,24 L24,24 L24,0 L0,0 Z M8,19 L5,19 L5,8 L8,8 L8,19 Z M6.5,6.732 C5.534,6.732 4.75,5.942 4.75,4.968 C4.75,3.994 5.534,3.204 6.5,3.204 C7.466,3.204 8.25,3.994 8.25,4.968 C8.25,5.942 7.467,6.732 6.5,6.732 Z M20,19 L17,19 L17,13.396 C17,10.028 13,10.283 13,13.396 L13,19 L10,19 L10,8 L13,8 L13,9.765 C14.397,7.179 20,6.988 20,12.241 L20,19 Z" />
      </g>
    </SvgIcon>
  )
}

export function EmailIcon(props) {
  return(
    <SvgIcon {...props} classes={{ root: useStyles().socialIcons }}>
      <defs>
        <linearGradient x1="0%" y1="0%" x2="101.999998%" y2="100.999999%" id="linearGradient-1">
            <stop stop-color="#1100B3" offset="0%"></stop>
            <stop stop-color="#B700D5" offset="100%"></stop>
        </linearGradient>
      </defs> 
      <g fill="none" stroke="url(#grad)" stroke-width="0.95">
        <path d="M0,0 L24,0 L24,24 L0,24 L0,0 Z M4.51098633,7.31286621 L19.486084,7.31286621 L4.51098633,7.31286621 Z M11.991539,13.6036786 L5,8 L5,17 L18.983078,17 L18.983078,8.23815918 L11.991539,13.6036786 Z" />
      </g>
    </SvgIcon>
  )
}

export function GitHubIcon(props) {
  return(
    <SvgIcon {...props} classes={{ root: useStyles().socialIcons }}>
      <defs>
        <linearGradient x1="0%" y1="0%" x2="101.999998%" y2="100.999999%" id="linearGradient-1">
            <stop stop-color="#1100B3" offset="0%"></stop>
            <stop stop-color="#B700D5" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g fill="none" stroke="url(#grad)" stroke-width="1.0">
        <path d="M0,0 L0,24 L24,24 L24,0 L0,0 Z M14.534,19.59 C14.128,19.668 14,19.419 14,19.206 L14,17.011 C14,16.264 13.738,15.778 13.45,15.53 C15.232,15.332 17.104,14.655 17.104,11.583 C17.104,10.709 16.793,9.995 16.28,9.436 C16.363,9.234 16.637,8.42 16.201,7.319 C16.201,7.319 15.53,7.104 14.003,8.139 C13.364,7.959 12.68,7.872 12,7.868 C11.32,7.871 10.636,7.959 9.997,8.137 C8.469,7.102 7.797,7.317 7.797,7.317 C7.363,8.419 7.637,9.232 7.72,9.435 C7.208,9.995 6.896,10.708 6.896,11.582 C6.896,14.646 8.763,15.333 10.541,15.536 C10.312,15.736 10.105,16.088 10.033,16.606 C9.576,16.81 8.419,17.163 7.705,15.94 C7.705,15.94 7.282,15.172 6.478,15.115 C6.478,15.115 5.698,15.105 6.423,15.602 C6.423,15.602 6.948,15.848 7.312,16.772 C7.312,16.772 7.775,18.2 10,17.716 L10,19.205 C10,19.416 9.871,19.664 9.472,19.59 C6.292,18.533 4,15.534 4,12 C4,7.581 7.582,4 12,4 C16.418,4 20,7.581 20,12 C20,15.533 17.711,18.531 14.534,19.59 Z" />
      </g>
    </SvgIcon>
  )
}

export function BoxedLogo(props) {
  return (
    <SvgIcon {...props} classes={{ root: useStyles().boxLogo }}>
      <g fill="url(#grad)" stroke-width="0.85">
        <path d="M0,0 L24,0 L24,23 L0,23 L0,0 Z M3.9588748,3 L3,3.88243123 L17.5484452,3.88243123 L6.30646482,19.0144928 L7.42754223,19.0144928 L19.629138,3 L3.9588748,3 Z M15.5642586,4.8538977 L4.25,20 L7.93164629,19.980141 L13.106097,13.0038355 L18.1184383,19.980141 L21,20 L21,19.2485507 L18.4429246,19.2286918 L13.106097,11.9166268 L7.60051025,19.2286918 L5.8596461,19.235214 L16.5841751,4.8538977 L15.5642586,4.8538977 Z M21,19.0144928 L21,18.046301 L19,18.046301 L14.4226264,11.8010647 L21,3 L19.8843056,3 L13.25,11.8010647 L18.5717239,19.0144928 L21,19.0144928 Z" id="Combined-Shape"></path>
      </g>
   </SvgIcon>
  )
}

export function Logo(props) {
  return (
    <SvgIcon {...props} classes={{ root: useStyles().logoIcon }}>
      <defs>
        <linearGradient x1="0%" y1="0%" x2="106.95733%" y2="107.596822%" id="linearGradient-1">
            <stop stop-color={props.colorOne} offset="0%"></stop>
            <stop stop-color={props.colorTwo} offset="100%"></stop>
        </linearGradient>
      </defs>
      <g id="Page-1" stroke="none" stroke-width="0" fill="none" fill-rule="evenodd">
          <g id="Desktop-HD" transform="translate(-467.000000, -441.000000)" fill="url(#grad)">
              <path d="M467.958875,441 L483.629138,441 L471.427542,457.014493 L470.306465,457.014493 L481.548445,441.882431 L467,441.882431 L467.958875,441 Z M479.564259,442.853898 L480.584175,442.853898 L469.859646,457.235214 L471.60051,457.228692 L477.106097,449.916627 L482.442925,457.228692 L485,457.248551 L485,458 L482.118438,457.980141 L477.106097,451.003836 L471.931646,457.980141 L468.25,458 L479.564259,442.853898 Z M485,457.014493 L482.571724,457.014493 L477.25,449.801065 L483.884306,441 L485,441 L478.422626,449.801065 L483,456.046301 L485,456.046301 L485,457.014493 Z" id="Shape"></path>
          </g>
      </g>
    </SvgIcon>
  )
}
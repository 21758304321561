import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import { Grid, Typography, Divider, Button } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles"
import  { Logo, LinkedInIcon, GitHubIcon, EmailIcon } from "../utils/socials"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: `0.75rem`
  },
  link: {
    fontSize: `0.75rem`,
    fontFamily: `Work Sans`,
    color: `purple`,
    textDecoration: `none`,
    boxShadow: `none`,
    '&:hover': {
      textDecoration: `underline`
    },
    '&:visited': {
      fontSize: `0.75rem`,
      fontFamily: `Work Sans`,
      color: `purple`,
      textDecoration: `none`,
      boxShadow: `none`
    }
  },
  body: {
    fontWeight: 700,
    fontSize: `1.25rem`
  }
}))

const Header = ({ siteTitle }) => (
  <header>
    <Grid container direction="column" justify="space-evenly" className={useStyles().root}>
      <Grid container justify="space-between" alignItems="center" spacing={0}>
        <Grid item xs>
          <Link to="/"><Logo colorOne="#C955FF" colorTwo="#B4EC51"/></Link>
        </Grid>
        <Grid container justify="flex-end" xs={3} spacing={0}>
          <Grid item xs><a href="https://linkedin.com/in/zain-khalid" target="_blank" rel="noopener noreferrer"><LinkedInIcon color="primary" /></a></Grid>
          <Grid item xs><a href="https://github.com/zain-ak" target="_blank" rel="noopener noreferrer"><GitHubIcon color="primary" /></a></Grid>
          <Grid item xs><a href="mailto:zain@zainkhalid.me?subject=Hello, Zain!" target="_blank" rel="noopener noreferrer"><EmailIcon color="primary" /></a></Grid>  
        </Grid>
      </Grid>
      <Grid container justify="flex-start">
        <Grid item alignItems="center" xs>
          <Typography variant="body1" className={useStyles().body}>{siteTitle}</Typography>
          <Typography variant="subtitle2">Full Stack Web & Android Developer</Typography>
        </Grid>
        <Grid container direction="row" justify="flex-end" alignItems="center" xs spacing={3}>
          <Grid item><Button><Link to="/blog"><Typography variant="button" className={useStyles().link}>Blog</Typography></Link></Button></Grid>
          <Grid item><Button><Link to="/portfolio"><Typography variant="button" className={useStyles().link}>Portfolio</Typography></Link></Button></Grid>
          <Grid item><Button><Link to="/about"><Typography variant="button" className={useStyles().link}>About | Contact</Typography></Link></Button></Grid>
        </Grid>
      </Grid>
    </Grid>
    <Divider style={{ marginTop: `0.75rem`, marginBottom: `0.75rem` }}/>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Zain Khalid`,
}

export default Header

const styles = theme => ({
  MuiCard: {
    root: {
      '&.MuiProjectCard--01': {
        transition: '0.3s',
        maxWidth: 400,
        height: 400,
        margin: 'auto',
        marginBottom: theme.spacing(4),
        borderRadius: 16,
        padding: theme.spacing(3),
        boxShadow: '0 4px 20px -12px rgba(0,0,0,0.3)',
        '&:hover': {
          boxShadow: '0 10px 40px -12.125px rgba(0,0,0,0.3)',
        },
        '& .MuiCardMedia-root': {
          height: '60px',
          width: '60px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center'

        },
        '& .MuiCard__head': {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '& .MuiAvatar-root': {
            width: 60,
            height: 60,
            backgroundColor: '#ffffff',
            transform: 'translateY(50%)',
          },
          '& .MuiTypography--headLabel': {
            fontFamily: `Work Sans`,
            color: theme.palette.grey[500],
          },
        },
        '& .MuiDivider-root': {
          marginLeft: -theme.spacing(3),
          marginRight: -theme.spacing(3),
        },
        '& .MuiCardContent-root': {
          textAlign: 'left',
          padding: 0,
          paddingTop: theme.spacing(2),
          '& .MuiTypography--overline': {
            fontFamily: `Work Sans`,
            fontSize: 16,
            fontWeight: 'bold',
            color: theme.palette.grey[500],
          },
          '& .MuiTypography--heading': {
            fontFamily: `Work Sans`,
            fontWeight: 700,
          },
          '& .MuiTypography--subheading': {
            fontFamily: `Work Sans`,
            lineHeight: 1.8,
          },
        },
      },
    },
  },
})

export default styles